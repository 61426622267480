import React, { useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';

const AddQuestions = () => {
  const { id } = useParams();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [questionsList, setQuestionsList] = useState([]);
  const [userResponses, setUserResponses] = useState({});
  
  const [isDataFetched, setIsDataFetched] = useState(false);
  useEffect(() => {
    if (!isDataFetched) {
      const fetchQuestions = async () => {
        try {
          const apiUrl = 'https://dxp2935.uta.cloud/php/takeexam.php';
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ exam_id: id }),
          });

          const data = await response.json();
          setQuestionsList(data);
          setIsDataFetched(true); // Set the flag to true after the first successful fetch
        } catch (error) {
          console.error('Error fetching questions:', error);
        }
      };

      fetchQuestions();
    }
  }, [id, isDataFetched])

  useEffect(() => {
  },[userResponses])

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    setUserResponses((userResponses) => ({
      ...userResponses,
      [questionIndex]: selectedOption,
    }));
    setQuestionIndex((prevIndex) => prevIndex + 1);
    setSelectedOption(null); // Reset selected option for the next question
  };

  const handleSubmitExam = async() => {
    // console.log(userResponses);
    // const id = localStorage.getItem('id');
    // console.log(id)
    try {
      // Retrieve user id from local storage
      const userId = localStorage.getItem('id');

      // Prepare the data to be sent in the request body
      const requestData = {
          exam_id: id, // Replace with the actual exam_id value
          id: userId,
          userResponses: userResponses,
      };

      // Make a POST request to your API
      const response = await fetch('https://dxp2935.uta.cloud/php/takeexamapi.php', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
      });

      if (response.ok) {
          const responseData = await response.json();
          console.log('API Response:', responseData);
          // Handle the API response as needed
      } else {
          console.error('Error submitting exam:', response.status);
          // Handle the error as needed
      }
  } catch (error) {
      console.error('Error submitting exam:', error);
      // Handle the error as needed
  }
  };

  return (
    <div style={styles.container}>
      <div style={styles.questionPanel}>
        <h2 style={styles.heading}>Exam ID:{id}</h2>
        {questionIndex < questionsList.length ? (
          <>
            <h3 style={styles.questionHeading}>Question {questionIndex + 1}:</h3>
            <p style={styles.questionText}>{questionsList[questionIndex].question_text}</p>
            {/* <div style={styles.optionsList}>
              {questionsList[questionIndex].options.map((option, index) => (
                <label key={index} style={styles.optionLabel}>
                  <input
                    type="radio"
                    value={option}
                    checked={selectedOption === option}
                    onChange={() => handleOptionChange(option)}
                    style={styles.radioInput}
                  />
                  {option}
                </label>
              ))}
            </div> */}
            <div style={styles.optionsList}>
            <label key={1} style={styles.optionLabel}>
                  <input
                    type="radio"
                    value={questionsList[questionIndex].optionA}
                    checked={selectedOption === questionsList[questionIndex].optionA}
                    onChange={() => handleOptionChange(questionsList[questionIndex].optionA)}
                    style={styles.radioInput}
                  />
                  {questionsList[questionIndex].optionA}
            </label>
            <label key={2} style={styles.optionLabel}>
                  <input
                    type="radio"
                    value={questionsList[questionIndex].optionB}
                    checked={selectedOption === questionsList[questionIndex].optionB}
                    onChange={() => handleOptionChange(questionsList[questionIndex].optionB)}
                    style={styles.radioInput}
                  />
                  {questionsList[questionIndex].optionB}
            </label>
            <label key={3} style={styles.optionLabel}>
                  <input
                    type="radio"
                    value={questionsList[questionIndex].optionC}
                    checked={selectedOption === questionsList[questionIndex].optionC}
                    onChange={() => handleOptionChange(questionsList[questionIndex].optionC)}
                    style={styles.radioInput}
                  />
                  {questionsList[questionIndex].optionC}
            </label>
            <label key={4} style={styles.optionLabel}>
                  <input
                    type="radio"
                    value={questionsList[questionIndex].optionD}
                    checked={selectedOption === questionsList[questionIndex].optionD}
                    onChange={() => handleOptionChange(questionsList[questionIndex].optionD)}
                    style={styles.radioInput}
                  />
                  {questionsList[questionIndex].optionD}
            </label>
            </div>
            <button onClick={handleNextQuestion} style={styles.nextButton}>
              Next Question
            </button>
          </>
        ) : (
          <div style={styles.submitPanel}>
            <h3 style={styles.subHeading}>All questions answered!</h3>
            <button onClick={handleSubmitExam} style={styles.submitButton}>
              Submit Exam
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  questionPanel: {
    flex: '1',
    backgroundColor: '#f8f9fa',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    color: '#007bff',
    textAlign: 'center',
  },
  questionHeading: {
    color: '#333',
  },
  questionText: {
    marginBottom: '15px',
  },
  optionsList: {
    marginBottom: '20px',
  },
  optionLabel: {
    display: 'block',
    marginBottom: '8px',
  },
  radioInput: {
    marginRight: '8px',
  },
  nextButton: {
    padding: '10px',
    backgroundColor: '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '100%',
  },
  submitPanel: {
    textAlign: 'center',
  },
  subHeading: {
    color: '#333',
  },
  submitButton: {
    padding: '10px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
  },
};

export default AddQuestions;
