import React from 'react';
import { Link } from 'react-router-dom';

function FilesInstructor() {
  // You can add JavaScript logic here for managing files if needed.

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/student-pro">Student</Link>
        <Link to="/Messages-p">Message</Link>
        <Link to="/instructor-pro">Instructor</Link>
        <Link to="/index">Logout</Link>
      </nav>
      <div className="container">
        <h2>Files</h2>
        <p>Welcome to the Files page. Here, you can access and manage your course-related files and resources.</p>
        <ul>
          <li><Link to="#">Course Syllabus</Link></li>
          <li><Link to="#">Lecture Notes</Link></li>
          <li><Link to="#">Assignments</Link></li>
          <li><Link to="#">Reading Materials</Link></li>
        </ul>
      </div>
      <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default FilesInstructor;
