import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Files() {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    // Fetch course data from your backend using PHP or any other API
    // and set it to the 'courses' state variable.
    fetch('your-backend-api-url')
      .then((response) => response.json())
      .then((data) => setCourses(data))
      .catch((error) => console.error('Error fetching data: ', error));
  }, []);

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/files">Files</Link>
        <Link to="/grades">Grades</Link>
        <Link to="/FeedbackPage">Feedback</Link>
        <Link to="/index">Logout</Link>
      </nav>
      <div className="container">
        <h2>Files</h2>
        <p>Welcome to the Files page. Here, you can access and manage your course-related files and resources.</p>
        <div>
          {courses.map((course) => (
            <div key={course.id}>
              <h3>{course.name}</h3>
              <ul>
                <li>
                  <Link to={`/syllabus/${course.id}`}>Course Syllabus</Link>
                </li>
                <li>
                  <Link to={`/notes/${course.id}`}>Lecture Notes</Link>
                </li>
                <li>
                  <Link to={`/assignments/${course.id}`}>Assignments</Link>
                </li>
                <li>
                  <Link to={`/readings/${course.id}`}>Reading Materials</Link>
                </li>
              </ul>
            </div>
          ))}
        </div>
      </div>
      <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default Files;
