
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const StudentDashboard = () => {
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [chatbotResponses, setChatbotResponses] = useState({
    'hi': 'Hello!',
    'how are you?': 'I am just a chatbot. How can I help you today?',
    'what is the program schedule?': 'You can find the program schedule on our website.',
    'how can I contact support?': 'You can reach our support team at support@example.com.',
    'tell me about the admission process.': 'You can find information about the admission process on our website.',
    'what are the tuition fees?': 'Tuition fees vary, please check our website for the most up-to-date information.',
    'can you recommend some study materials?': 'We recommend the following study materials: (List of materials).',
    'how can I reset my password?': 'You can reset your password by visiting our login page and clicking on the "Forgot Password" link.',
    'what are the office hours?': 'Our office hours are from 9:00 AM to 5:00 PM, Monday to Friday.',
    'tell me more about the faculty.': 'Our faculty members are experienced professionals with expertise in various fields.',
    'where is the campus located?': 'Our campus is located at (address).'
    
  });

  const handleChatInputChange = (event) => {
    setNewMessage(event.target.value);
  };
  const buttonStyle = {
    display: 'inline-block',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    textDecoration: 'none',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    margin: '5px',
    fontSize: '16px',
  };

  const handleSendChatMessage = () => {
    const message = newMessage.toLowerCase(); // Convert the message to lowercase
    if (message === 'bye') {
      // Clear the chat
      setChatMessages([]);
    } else {
      // Add the user's message to the chat
      setChatMessages([...chatMessages, { message: newMessage, user: true }]);
      
      // Check if the message has a predefined response
      if (chatbotResponses[message]) {
        const response = chatbotResponses[message];
        setChatMessages([...chatMessages, { message: response, user: false }]);
      } else {
        // If there's no predefined response, you can have a default response
        setChatMessages([...chatMessages, { message: "I don't understand your question.", user: false }]);
      }
    }

    // Clear the input field
    setNewMessage('');
  };
 
  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/test">Files</Link>
        {/* <Link to="/chat" style={buttonStyle}>
          Chat
        </Link> */}
        <Link to="/chat">Chat</Link>
        <Link to="/FeedbackPage">Feedback</Link>
        <Link to="/">Logout</Link>
      </nav>
      <div className="container">
        <div className="content">
          <div className="dashboard">
            <div className="section">
              <h2>CSE 5321-001</h2>
              {/* <Link to="/analytics">ANALYTICS</Link>&nbsp; */}
              {/* <Link to="/reportstudent">REPORTS</Link>&nbsp; */}
              <Link to="/TakeExams/1">TAKE AN ASSESSMENT</Link>
              
            </div>
            <div className="section">
              <h2>CSE 5334-004</h2>
              {/* <Link to="/analytics">ANALYTICS</Link>&nbsp; */}
              {/* <Link to="/reportstudent">REPORTS</Link>&nbsp; */}
              <Link to="/TakeExams/2">TAKE AN ASSESSMENT</Link>
            </div>
            <div className="section">
              <h2>CSE 6324-003</h2>
              {/* <Link to="/analytics">ANALYTICS</Link>&nbsp; */}
              {/* <Link to="/reportstudent">REPORTS</Link>&nbsp; */}
              <Link to="/TakeExams/3">TAKE AN ASSESSMENT</Link>
            </div>
          </div>
        </div>
        
      </div>
      <footer>
        <p>@ copyright EDUHUB-360</p>
      </footer>
    </div>
  );
};

export default StudentDashboard;