import React from 'react';
import { Link } from 'react-router-dom';
function FacultyDevelopmentPage() {
  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/Blog">Blog</Link>
      <Link to="/ContactUs">Contact Us</Link>
      <Link to="/services">Services</Link>
      <Link to="/">logout</Link>
    </nav>
      <h3>Faculty Development</h3>
      <p>
        Welcome to the Faculty Development page. We are dedicated to supporting our faculty's growth and professional development. Here are some key aspects of our faculty development program:
      </p>

      <h4>1. Continuous Learning Opportunities</h4>
      <p>
        Our faculty members have access to a wide range of continuous learning opportunities. This includes workshops, seminars, and webinars on teaching methodologies, educational technology, and best practices in their respective fields.
      </p>

      <h4>2. Mentorship and Coaching</h4>
      <p>
        We provide mentorship and coaching programs to support our faculty's career development. Experienced educators offer guidance and feedback to help faculty members excel in their roles.
      </p>

      <h4>3. Research Support</h4>
      <p>
        We encourage and support faculty research. Our institution offers resources and funding to assist faculty members in their research endeavors, fostering an environment of academic innovation.
      </p>

      <h4>4. Curriculum Enhancement</h4>
      <p>
        Faculty are involved in ongoing curriculum enhancement efforts. They play a crucial role in updating and revising course materials to reflect the latest developments in their fields.
      </p>

      <h4>5. Collaboration and Networking</h4>
      <p>
        We facilitate collaboration and networking opportunities for our faculty members, both within the institution and with external partners. These connections help in sharing knowledge and improving teaching strategies.
      </p>

      <h4>6. Professional Development Funds</h4>
      <p>
        Faculty members have access to professional development funds to attend conferences, enroll in advanced courses, and acquire additional certifications to further their expertise.
      </p>

      

      <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default FacultyDevelopmentPage;
