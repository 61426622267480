import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import FeedbackDisplay from './FeedbackDisplay';

function AdminDashboard() {
  const [chatInput, setChatInput] = useState('');
  const [chatContent, setChatContent] = useState([]);



  const handleChatInputChange = (e) => {
    setChatInput(e.target.value);
  };

 

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/managecourses">Courses</Link>
        <Link to="/Manageprogram">Program</Link>
        <Link to="/FeedbackDisplay">Feedbacks</Link> {/* Link to the FeedbackDisplay page */}
        <Link to="/reportspage">Reports</Link>
        <Link to="/chat">Chat</Link>
        <Link to="/">Logout</Link>
      </nav>
      <Switch>
        <Route path="/FeedbackDisplay" component={FeedbackDisplay} />
      </Switch>
      <div className="container">
        <div className="content">
          <div className="dashboard">
            <div className="section">
              <h2>Coordinator</h2>
              {/* <Link to="/programreviewpage"><button>Reviews</button></Link> */}
              {/* <Link to="/assigned-students"><button>Assigned Students</button></Link> */}
              <Link to="/managecoordinator"><button>Manage Coordinator</button></Link>
            </div>
            <div className="section">
              <h2>Student</h2>
              {/* <Link to="/managecourses"><button>Manage Course</button></Link> */}
              {/* <Link to="/reportspage"><button>Reports</button></Link> */}
              {/* <Link to="/performance"><button>Performance</button></Link> */}
              <Link to="/managestudents"><button>Manage Students</button></Link>
            </div>
            <div className="section">
              <h2>QA Officer</h2>
              {/* <Link to="/managecourses"><button>Assign Course</button></Link> */}
              {/* <Link to="/reportqa"><button>Reports</button></Link> */}
              <Link to="/manageqa"><button>Manage QA Officer</button></Link>
              {/* <Link to="/permissions"><button>Permissions</button></Link> */}
            </div>
          </div>
        </div>
        
      </div>
      <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default AdminDashboard;
