import React from 'react';
import { Link } from 'react-router-dom';

function Grades() {
  // Define your grades data
  const gradesData = [
    { course: 'CSE 5321-001', grade: 'A' },
    { course: 'CSE 5334-004', grade: 'B+' },
    { course: 'CSE 6324-003', grade: 'A-' },
  ];

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/files">Files</Link>
        <Link to="/grades">Grades</Link>
        <Link to="/appointments">Appointments</Link>
        <Link to="/messages">Messages</Link>
        <Link to="/">Logout</Link>
        </nav>
      <div className="container">
        <center>
          <div className="content">
            <h2>Grades</h2>
            <p>View your course grades and performance on this page. Here are your current course grades:</p>
            <table>
              <thead>
                <tr>
                  <th>Course</th>
                  <th>Grade</th>
                </tr>
              </thead>
              <tbody>
                {gradesData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.course}</td>
                    <td>{item.grade}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </center>
      </div>
      <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default Grades;
