import React from 'react';
import image1 from './image1.jpg';
import image2 from './image2.jpg';
import image3 from './image3.jpg';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/Blog">Blog</Link>
      <Link to="/ContactUs">Contact Us</Link>
      <Link to="/services">Services</Link>
      <Link to="/login">Login</Link>
    </nav>
      <div className="container">
        <div className="content">
          <div className="text">
            <p>Welcome to the Academic Program System website. Our mission is to enhance the management and assessment of our Computer Science academic program, ensuring the highest quality education for our students.</p>
            <p>This website serves as a centralized platform for students, instructors, administrators, program coordinators, and quality assurance officers to streamline program-related activities, assessments, and communication.</p>
          </div>
          <div className="image-container">
            <img src={image1} alt="Image 1" />
            <img src={image2} alt="Image 2" />
            <img src={image3} alt="Image 3" />
          </div>
          <div className="text">
            <p>Effective performance measurement and assessment are essential for maintaining the quality and relevance of our academic program. It allows us to:</p>
            <ul>
              <li>Evaluate student learning outcomes</li>
              <li>Identify areas for improvement</li>
              <li>Enhance course content and objectives</li>
              <li>Ensure alignment with industry standards</li>
            </ul>
            <p>We believe that through data-driven insights and collaboration, we can continuously elevate the educational experience for our students.</p>
          </div>
        </div>
      </div>
      <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default Home;
