import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ProgramCoordinatorDashboard = () => {
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');


  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        {/* <Link to="/managestudents">Student</Link>
        <Link to="/messages-pro">Message</Link>
        <Link to="/manageinstructors">Instructor</Link> */}
        <Link to="/">Home</Link> 
        {/* <Link to="/test">Files</Link>    */}
        <Link to="/FeedbackPage">Feedback</Link>
        <Link to="/managestudents">Students</Link>
        <Link to="/chat">Chat</Link>
        <Link to="/">Logout</Link>        
      </nav>
      <div className="container">
        <div className="content">
          <div className="dashboard">
            <div className="section">
              <h2>Program Coordinator Dashboard</h2>
              <button><Link to="/reportspage">Reports</Link></button>&nbsp;
              <button><Link to="/manageinstructors">Manage Instructors</Link></button>&nbsp;
              <button><Link to="/managestudents">Manage Students</Link></button>&nbsp;
              <button><Link to="/FeedbackDisplay">View Feedback</Link></button>
              
            </div>
          </div>
        </div>
        
      </div>
      <footer>
        <p>@ copyright EDUHUB-360</p>
      </footer>
    </div>
  );
};

export default ProgramCoordinatorDashboard;
