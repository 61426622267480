import React, { useState } from 'react';
import { Link } from 'react-router-dom';
const StudentPro = () => {
  const [students, setStudents] = useState([
    { id: 1, name: 'Student 1', email: 'student1@example.com' },
    { id: 2, name: 'Student 2', email: 'student2@example.com' },
  ]);
  const [newStudent, setNewStudent] = useState({ name: '', email: '' });

  const handleAddStudent = () => {
    setStudents([...students, { id: students.length + 1, ...newStudent }]);
    setNewStudent({ name: '', email: '' });
  };

  const handleEditStudent = (id) => {
    // Implement edit functionality for a specific student based on their id
    alert(`Edit Student ${id}`);
  };

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/student-pro">Student</Link>
        <Link to="/Messages-pro">Message</Link>
        <Link to="/instructor-pro">Instructor</Link>
        <Link to="/">Logout</Link>
      </nav>
      <div className="container">
        <div className="content">
          <div className="dashboard">
            <section id="students">
              <h2>Manage Students</h2>
              <ul>
                {students.map((student) => (
                  <li key={student.id}>
                    <a href={`#edit-student${student.id}`}>
                      {student.name} - Email: {student.email} (Edit)
                    </a>
                  </li>
                ))}
              </ul>
              {students.map((student) => (
                <div id={`edit-student${student.id}`} className="edit-form" key={student.id}>
                  <h3>Edit Student {student.id}</h3>
                  <form>
                    <label htmlFor={`editStudentName${student.id}`}>Name:</label>
                    <input
                      type="text"
                      id={`editStudentName${student.id}`}
                      name={`editStudentName${student.id}`}
                      value={student.name}
                      required
                    />
                    <label htmlFor={`editStudentEmail${student.id}`}>Email:</label>
                    <input
                      type="email"
                      id={`editStudentEmail${student.id}`}
                      name={`editStudentEmail${student.id}`}
                      value={student.email}
                      required
                    />
                    <button onClick={() => handleEditStudent(student.id)}>
                      Edit Student
                    </button>
                  </form>
                </div>
              ))}
              <h3>Add New Student</h3>
              <form>
                <label htmlFor="studentName">Name:</label>
                <input
                  type="text"
                  id="studentName"
                  name="studentName"
                  value={newStudent.name}
                  onChange={(e) =>
                    setNewStudent({ ...newStudent, name: e.target.value })
                  }
                  required
                />
                <label htmlFor="studentEmail">Email:</label>
                <input
                  type="email"
                  id="studentEmail"
                  name="studentEmail"
                  value={newStudent.email}
                  onChange={(e) =>
                    setNewStudent({ ...newStudent, email: e.target.value })
                  }
                  required
                />
                <button onClick={handleAddStudent}>Add Student</button>
              </form>
            </section>
          </div>
        </div>
      </div>
      <footer>
        <p>@ copyright EDUHUB-360</p>
      </footer>
    </div>
  );
};

export default StudentPro;
