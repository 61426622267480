import React from 'react';
import { Link } from 'react-router-dom';

const MessagesPro = () => {
  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/student-pro">Student</Link>
        <Link to="/Messages-pro">Messages</Link>
        <Link to="/instructor-pro">Instructor</Link>
        <Link to="/">Logout</Link>
      </nav>
      <div className="container">
        <h2>Messages</h2>
        <p>Stay connected with your instructors and peers through messaging. You can send and receive messages here.</p>
        <h3>New Messages:</h3>
        <div className="message">
          <strong>From:</strong> Prof. Anderson
          <br />
          <strong>Date:</strong> October 10, 2023
          <br />
          <strong>Subject:</strong> Assignment Submission
          <p>Hello, please remember to submit your assignment by the deadline. Thank you!</p>
        </div>
        <div className="message">
          <strong>From:</strong> John Doe
          <br />
          <strong>Date:</strong> October 12, 2023
          <br />
          <strong>Subject:</strong> Group Project
          <p>Hi, we need to discuss our group project. When are you available to meet?</p>
        </div>
      </div>
      <footer>
        <p>@ copyright EDUHUB-360</p>
      </footer>
    </div>
  );
};

export default MessagesPro;
