// // FeedbackDisplay.js
// import React, { useState, useEffect } from 'react';

// const FeedbackDisplay = () => {
//   const [feedbackData, setFeedbackData] = useState([]);

//   // Simulating fetching data from a database
//   useEffect(() => {
//     // Replace this with actual API call to fetch data from your backend
//     const fetchData = async () => {
//       try {
//         // Assuming you have an API endpoint for fetching feedback
//         const response = await fetch('/api/feedback');
//         const data = await response.json();
//         setFeedbackData(data);
//       } catch (error) {
//         console.error('Error fetching feedback data:', error);
//       }
//     };

//     fetchData();
//   }, []); // Empty dependency array ensures this effect runs once when the component mounts

//   return (
//     <div style={{ fontFamily: 'Arial, sans-serif', maxWidth: '800px', margin: 'auto' }}>
//       <header>
//         <h1 style={{ textAlign: 'center' }}>Feedback Display</h1>
//       </header>
//       <div className="container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//         <div className="content" style={{ width: '100%' }}>
//           <div className="dashboard" style={{ textAlign: 'center' }}>
//             <h3>Feedback Table</h3>
//             <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
//               <thead style={{ backgroundColor: '#f2f2f2' }}>
//                 <tr>
//                   <th style={{ padding: '10px', border: '1px solid #ddd' }}>Email</th>
//                   <th style={{ padding: '10px', border: '1px solid #ddd' }}>Feedback</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {feedbackData.map((feedback, index) => (
//                   <tr key={index}>
//                     <td style={{ padding: '10px', border: '1px solid #ddd' }}>{feedback.email}</td>
//                     <td style={{ padding: '10px', border: '1px solid #ddd' }}>{feedback.comment}</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FeedbackDisplay;

// FeedbackDisplay.js
import React, { useState, useEffect } from 'react';

const FeedbackDisplay = () => {
  const [feedbackData, setFeedbackData] = useState([]);

  // Fetch feedback data from the API
  useEffect(() => {
    const fetchFeedbackData = async () => {
      try {
        const response = await fetch('https://dxp2935.uta.cloud/php/retrieve-feedback.php'); // Replace with your API endpoint
        const data = await response.json();
        setFeedbackData(data);
      } catch (error) {
        console.error('Error fetching feedback data:', error);
      }
    };

    fetchFeedbackData();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', maxWidth: '800px', margin: 'auto' }}>
      <header>
        <h1 style={{ textAlign: 'center' }}>Feedback Display</h1>
      </header>
      <div className="container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className="content" style={{ width: '100%' }}>
          <div className="dashboard" style={{ textAlign: 'center' }}>
            <h3>Feedback Table</h3>
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
              <thead style={{ backgroundColor: '#f2f2f2' }}>
                <tr>
                  <th style={{ padding: '10px', border: '1px solid #ddd' }}>Email</th>
                  <th style={{ padding: '10px', border: '1px solid #ddd' }}>Feedback</th>
                </tr>
              </thead>
              <tbody>
                {feedbackData.map((feedback, index) => (
                  <tr key={index}>
                    <td style={{ padding: '10px', border: '1px solid #ddd' }}>{feedback.email}</td>
                    <td style={{ padding: '10px', border: '1px solid #ddd' }}>{feedback.query}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackDisplay;
