import React from 'react';
import { Link } from 'react-router-dom';
// import Pdf from '../CSE_5335_FALL2023_Section3_103.pdf'
import SyllabusPdf from '../CSE_5335_FALL2023_Section3_103.pdf';
import NotesPdf from '../notes.pdf';
import DescPdf from '../desc.pdf';

function Student() {
    return (
        <div>
          <header>
            <h1>EDUHUB-360</h1>
          </header>
          <nav>
            <Link to="/StudentDashboard">Home</Link>
            <Link to="/Chat">Chat</Link>
            <Link to="/FeedbackPage">Feedback</Link>
            <Link to="/">Logout</Link>
          </nav>
          <div className="container">
            <div className="content">
              <div className="dashboard">
                <div className="section">
                  <h2>CSE 5321-001</h2>
                  <button><a href={SyllabusPdf} target='_blank'>Syllabus</a>&nbsp;</button>
                  <button><a href={NotesPdf} target='_blank'>Notes</a>&nbsp;</button>
                  <button><a href={DescPdf} target='_blank'>Description</a>&nbsp;</button>
                </div>
                <div className="section">
                  <h2>CSE 5334-004</h2>
                  <button><a href={SyllabusPdf} target='_blank'>Syllabus</a>&nbsp;</button>
                  <button><a href={NotesPdf} target='_blank'>Notes</a>&nbsp;</button>
                  <button><a href={DescPdf} target='_blank'>Description</a>&nbsp;</button>
                </div>
                <div className="section">
                  <h2>CSE 6324-003</h2>
                  <button><a href={SyllabusPdf} target='_blank'>Syllabus</a>&nbsp;</button>
                  <button><a href={NotesPdf} target='_blank'>Notes</a>&nbsp;</button>
                  <button><a href={DescPdf} target='_blank'>Description</a>&nbsp;</button>
                </div>
              </div>
            </div>
            
          </div>
          <footer>
            <p>@ copyright EDUHUB-360</p>
          </footer>
        </div>
      );
    };
    
    export default Student;