import React, { useState, useEffect } from 'react';
import AddInstructor from './AddInstructor';
import EditInstructor from './EditInstructor';
import { Link } from 'react-router-dom';

// CSS for the grid layout
const gridStyles = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr', 
  gridGap: '20px', 
};


function ManageInstructors() {
  const [instructors, setInstructors] = useState([]);

  useEffect(() => {
    // Fetch the list of instructors to populate the list
    fetch('https://dxp2935.uta.cloud/php/retrieve-instructors.php')
      .then((response) => response.json())
      .then((data) => {
        setInstructors(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);
  
  const handleDelete = (id) => {
    // Send a DELETE request to delete the student with the specified id
    fetch(`https://dxp2935.uta.cloud/php/delete-instructor.php?instructor_id=${id}`, {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // If the deletion is successful, fetch the updated list of students
          fetch('https://dxp2935.uta.cloud/php/retrieve-instructors.php')
            .then((response) => response.json())
            .then((data) => {
              setInstructors(data);
            })
            .catch((error) => {
              console.error('Error fetching updated Instructor:', error);
            });
        } else {
          console.error('Error deleting Instructor:', data.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/Blog">Blog</Link>
        <Link to="/ContactUs">Contact Us</Link>
        <Link to="/services">Services</Link>
        <Link to="/">Logout</Link>
      </nav>
      <div className="container">
        <center>
          <h1>Manage Instructors</h1>
        </center>

        <div style={gridStyles}>
          <AddInstructor />
          <EditInstructor instructors={instructors} />
        </div>

        {/* Render the list of instructors */}
        <ul>
          {instructors.map((instructor) => (
            <li key={instructor.id}>
              {instructor.name} - {instructor.email} - {instructor.department}
              <button onClick={() => handleDelete(instructor.id)}>Delete</button>
            </li>
          ))}
        </ul>
      </div>
      <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default ManageInstructors;
