import React from 'react';
import './styles.css';
import { Link } from 'react-router-dom';
function StudentSupportPage() {
  return (
    
    <div>
     <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/Blog">Blog</Link>
      <Link to="/ContactUs">Contact Us</Link>
      <Link to="/services">Services</Link>
      <Link to="/">logout</Link>
    </nav>
      <center><h3>Student Performance Analysis and Recommendations</h3></center>
      <div class="container">
      <p>
        Welcome to the Student Performance Analysis and Recommendations page. Here, we provide an overview of the analysis of student performance and offer recommendations for improvement.
      </p>
      <h4>Performance Overview</h4>
      <p>
        Our analysis indicates that overall student performance has been positive over the last academic year. Here are some key highlights:
      </p>
      <ul>
        <li>Increased average GPA across all departments</li>
        <li>Higher student attendance rates in online classes</li>
        <li>Improved completion rates for assignments and projects</li>
      </ul>
      <h4>Recommendations for Improvement</h4>
      <p>
        Despite the positive trends, there is always room for improvement. Based on our analysis, we recommend the following actions to further enhance student performance:
      </p>
      <ol>
        <li>Implement additional tutoring and academic support programs for struggling students.</li>
        <li>Encourage active participation in extracurricular activities to boost student engagement.</li>
        <li>Provide more comprehensive feedback to students on their assignments and assessments.</li>
      </ol>
      <p>
        We believe that by taking these steps, we can continue to elevate the academic experience and success of our students.
      </p>
    </div> <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer></div>
  );
}




export default StudentSupportPage;
