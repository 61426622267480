import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function InstructorPro() {
  // Sample instructor data
  const [instructors, setInstructors] = useState([
    { id: 1, name: 'Instructor 1', email: 'instructor1@example.com' },
    { id: 2, name: 'Instructor 2', email: 'instructor2@example.com' },
  ]);

  // Form input state
  const [instructorName, setInstructorName] = useState('');
  const [instructorEmail, setInstructorEmail] = useState('');

  // Edit instructor state
  const [editInstructorId, setEditInstructorId] = useState(null);
  const [editInstructorName, setEditInstructorName] = useState('');
  const [editInstructorEmail, setEditInstructorEmail] = useState('');

  // Function to add a new instructor
  const addInstructor = () => {
    if (instructorName && instructorEmail) {
      const newInstructor = {
        id: instructors.length + 1,
        name: instructorName,
        email: instructorEmail,
      };
      setInstructors([...instructors, newInstructor]);
      setInstructorName('');
      setInstructorEmail('');
    }
  };

  // Function to edit an existing instructor
  const editInstructor = () => {
    if (editInstructorName && editInstructorEmail) {
      const updatedInstructors = instructors.map((instructor) =>
        instructor.id === editInstructorId
          ? { id: instructor.id, name: editInstructorName, email: editInstructorEmail }
          : instructor
      );
      setInstructors(updatedInstructors);
      setEditInstructorId(null);
      setEditInstructorName('');
      setEditInstructorEmail('');
    }
  };

  // Function to set the edit state for an instructor
  const setEditState = (id, name, email) => {
    setEditInstructorId(id);
    setEditInstructorName(name);
    setEditInstructorEmail(email);
  };

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/student-pro">Student</Link>
        <Link to="/Messages-pro">Message</Link>
        <Link to="/instructor-pro">Instructor</Link>
        <Link to="/">Logout</Link>
      </nav>
      <div className="container">
        <div className="content">
          <div className="dashboard">
            <section id="instructors">
              <h2>Manage Instructors</h2>
              {/* Sample Instructor List */}
              <ul>
                {instructors.map((instructor) => (
                  <li key={instructor.id}>
                    <span>
                      {instructor.name} - Email: {instructor.email}
                    </span>
                    <button onClick={() => setEditState(instructor.id, instructor.name, instructor.email)}>
                      Edit
                    </button>
                  </li>
                ))}
              </ul>
              {/* Add/Edit Instructor Forms */}
              {editInstructorId !== null && (
                <div className="edit-form">
                  <h3>Edit Instructor {editInstructorId}</h3>
                  <form>
                    <label htmlFor="editInstructorName">Name:</label>
                    <input
                      type="text"
                      id="editInstructorName"
                      name="editInstructorName"
                      value={editInstructorName}
                      onChange={(e) => setEditInstructorName(e.target.value)}
                      required
                    />
                    <label htmlFor="editInstructorEmail">Email:</label>
                    <input
                      type="email"
                      id="editInstructorEmail"
                      name="editInstructorEmail"
                      value={editInstructorEmail}
                      onChange={(e) => setEditInstructorEmail(e.target.value)}
                      required
                    />
                    <button onClick={editInstructor}>Edit Instructor</button>
                  </form>
                </div>
              )}
              {/* Add Instructor Form */}
              <h3>Add New Instructor</h3>
              <form>
                <label htmlFor="instructorName">Name:</label>
                <input
                  type="text"
                  id="instructorName"
                  name="instructorName"
                  value={instructorName}
                  onChange={(e) => setInstructorName(e.target.value)}
                  required
                />
                <label htmlFor="instructorEmail">Email:</label>
                <input
                  type="email"
                  id="instructorEmail"
                  name="instructorEmail"
                  value={instructorEmail}
                  onChange={(e) => setInstructorEmail(e.target.value)}
                  required
                />
                <button onClick={addInstructor}>Add Instructor</button>
              </form>
            </section>
          </div>
        </div>
      </div>
      <footer>
        <p>@ copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default InstructorPro;
