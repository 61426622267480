import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function InstructorDashboard() {
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  const chatbotResponses = {
    'hi': 'Hello!',
    'how are you?': 'I am just a chatbot. How can I help you today?',
    'course creation': 'You can create a new course from the Course Creation section.',
    'create exam': 'You can create an exam in the Create Exam section.',
    'grade peers': 'You can grade your students\' peers in the Grade Peers section.',
    'students': 'You can access student profiles from the Students section.',
    'exams': 'You can view exam details from the Exams section.',
    'student 1': 'Viewing Student 1 profile...',
    'student 2': 'Viewing Student 2 profile...',
    'student 3': 'Viewing Student 3 profile...',
    'student 4': 'Viewing Student 4 profile...',
    'exam 1': 'Viewing Exam 1 details...',
    'exam 2': 'Viewing Exam 2 details...',
    'exam 3': 'Viewing Exam 3 details...',
    'exam 4': 'Viewing Exam 4 details...',
    'clear chat': 'Chat cleared.',
  };
  const buttonStyle = {
    display: 'inline-block',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    textDecoration: 'none',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    margin: '5px',
    fontSize: '16px',
  };

  const handleQuickSend = (message) => {
    if (message) {
      const response = chatbotResponses[message];
      if (response) {
        setChatMessages([...chatMessages, message, response]);
      } else {
        setChatMessages([...chatMessages, message, "I don't understand your question."]);
      }
    }
  };

  const handleChatInputChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleSendChatMessage = () => {
    if (newMessage) {
      const message = newMessage.toLowerCase();
      if (chatbotResponses[message]) {
        setChatMessages([...chatMessages, message]);
        const response = chatbotResponses[message];
        setChatMessages([...chatMessages, response]);
      } else {
        setChatMessages([...chatMessages, "I don't understand your question."]);
      }
      setNewMessage('');
    }
  };

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/">Home</Link> 
        <Link to="/test">Files</Link>   
        <Link to="/FeedbackPage">Feedback</Link>
        <Link to="/managestudents">Students</Link>
        {/* <Link to="/chat" style={buttonStyle}>
                  Chat
                </Link> */}
        <Link to="/chat">Chat</Link>
        <Link to="/">Logout</Link>
      </nav>
      <div className="container">
        <div className="content">
          <div className="dashboard">
          <div className="section">
        <h2>CSE 5321-001</h2>
        <Link to="/exam-creation">Create Exam</Link>&nbsp;
        <Link to="/Gradeexam">Grade Exam</Link>&nbsp;
        {/* <Link to="/gradepeers">Reports</Link>&nbsp; */}
      </div>
      <div className="section">
        <h2>CSE 5334-004</h2>
        <Link to="/exam-creation">Create Exam</Link>&nbsp;
        <Link to="/Gradeexam">Grade Exam</Link>&nbsp;
        {/* <Link to="/gradepeers">Reports</Link>&nbsp; */}
      </div>
      <div className="section">
        <h2>CSE 6324-003</h2>
        <Link to="/exam-creation">Create Exam</Link>&nbsp;
        <Link to="/Gradeexam">Grade Exam</Link>&nbsp;
        {/* <Link to="/gradepeers">Reports</Link>&nbsp; */}
      </div>
          </div>
        </div>
        
      </div>
      <footer>
        <p>@ copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default InstructorDashboard;
