import React from 'react';
import { Link } from 'react-router-dom';

function About() {
  return (
    <div>
    <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/Blog">Blog</Link>
      <Link to="/ContactUs">Contact Us</Link>
      <Link to="/services">Services</Link>
      <Link to="/login">Login</Link>
    </nav>
    <div className="text">
      <h2>About Our Academic Program</h2>
      <p>Welcome to the forefront of technology education – our Computer Science academic program. We're dedicated to nurturing the next generation of tech visionaries and problem solvers. Our program stands at the intersection of innovation and knowledge, preparing you for a diverse range of career opportunities in the dynamic field of computer science.</p>

      <p>Our curriculum is carefully crafted to provide a comprehensive understanding of the latest trends and technologies. Whether you dream of pioneering in software development, unraveling the mysteries of artificial intelligence, fortifying digital fortresses in cybersecurity, or delving into data science, our program offers a solid foundation and the flexibility to tailor your education to match your passions and career goals.</p>

      <p>At our institution, we're committed to fostering an environment that encourages innovation, sharpens critical thinking, and offers hands-on learning opportunities. Our esteemed faculty, equipped with industry experience, guides your journey, and our state-of-the-art facilities provide the perfect playground for exploration and experimentation.</p>

      <p>As you navigate our website, you'll find distinct user roles tailored to meet specific needs:</p>
      
      <ul>
        <li>Student</li>
        <li>Instructor</li>
        <li>Administrator</li>
        <li>Program Coordinator</li>
        <li>Quality Assurance Officer</li>
      </ul>
    </div>
    <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default About;
