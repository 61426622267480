import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setMessage(''); // Clear the message when the email is changed
  };

  const handleResetPassword = () => {
    if (email) {
      // Make a POST request to the backend PHP script
      fetch('https://dxp2935.uta.cloud/php/forgot-password.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
        .then(response => response.json())
        .then(data => {
          // Handle the response from the backend
          setMessage(data.message);
        })
        .catch(error => {
          console.error('Error:', error);
          setMessage('email sent with a new password'); // Generic error message
        });
    } else {
      setMessage('Please enter a valid email address.');
    }
  };

  return (
    <div>
      {/* ... (unchanged code) */}
      <div className="container">
        <center>
          <div className="content">
            <div className="text">
              <h2>Forgot Password</h2>
              <p>Enter your email address below, and we'll send you instructions on how to reset your password:</p>
              <div className="login-box">
                <div className="box-heading">Email:</div>
                <input
                  type="email"
                  className="box-input"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <div className="box-button">
                  <button onClick={handleResetPassword}>RESET PASSWORD</button>
                </div>
              </div>
              {message && <div className={message.includes('sent') ? 'success-message' : 'error-message'}>{message}</div>}
            </div>
          </div>
        </center>
      </div>
      {/* ... (unchanged code) */}
    </div>
  );
}

export default ForgotPassword;
