import React from 'react';
import { Link } from 'react-router-dom';

function InstructorQA() {
  // Function to handle the "Submit Feedback" button click
  const handleFeedbackSubmit = () => {
    // Add your logic here for handling feedback submission
    alert('Feedback submitted');
  };

  // Function to handle the "View Feedback" button click
  const handleViewFeedback = () => {
    // Add your logic here for viewing feedback
    alert('View feedback');
  };

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/student-qa">Student</Link>
        <Link to="/Messages-qa">Message</Link>
        <Link to="/instructor-qa">Instructor</Link>
        <Link to="/progcord-qa">Coordinator</Link>
        <Link to="/">Logout</Link>
      </nav>
      <center>
        <div className="container">
          <div className="section">
            <h2>Instructor Feedback</h2>
            <button onClick={handleFeedbackSubmit}>Submit Feedback</button>&nbsp;
            <button onClick={handleViewFeedback}>View Feedback</button>
          </div>
        </div>
      </center>
      <footer>
        <p>@ copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default InstructorQA;
