import React, { useState } from 'react';
import { Link } from 'react-router-dom';
function Course() {
  const [courseName, setCourseName] = useState('');
  const [courses, setCourses] = useState([]);

  const handleCreateCourse = () => {
    if (courseName) {
      setCourses([...courses, courseName]);
      setCourseName('');
    }
  };

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/Blog">Blog</Link>
      <Link to="/ContactUs">Contact Us</Link>
      <Link to="/services">Services</Link>
      <Link to="/">Logout</Link>
    </nav>
      <center><h2>Create Courses</h2></center>
      <div className="container">
        <div className="content">
          <div className="dashboard">
      <div>
        <input
          type="text"
          placeholder="Enter course name"
          value={courseName}
          onChange={(e) => setCourseName(e.target.value)}
        />
        <button onClick={handleCreateCourse}>Create Course</button>
      </div>
      <h3>Created Courses:</h3>
      <ul>
        {courses.map((course, index) => (
          <li key={index}>{course}</li>
        ))}
      </ul>
    </div></div></div> <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer></div>
  );
}

export default Course;
