import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './styles.css';
import { Link } from 'react-router-dom';
function ReportStudent() {
  // Sample data for the bar chart
  const data = [
    { category: 'Students', value: 500 },
    { category: 'Courses', value: 3 },
    { category: 'Attendance', value: 100 },
    { category: 'Graded Assignments', value: 4 },
  ];

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/Blog">Blog</Link>
      <Link to="/ContactUs">Contact Us</Link>
      <Link to="/services">Services</Link>
      <Link to="/">logout</Link>
    </nav>
     <center><h3>Reports</h3></center> 
     <div class="container">
      <p>
        This page shows the report of number of course taken, graded assignments and their attendence
      </p>

      <div style={{ width: '100%', height: 300 }}>
        <ResponsiveContainer>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" fill="red" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div> <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer></div>
  );
}

export default ReportStudent;
