import React, { useState, useEffect } from 'react';
import Addqa from './Addqa';
import Editqa from './Editqa';
import { Link } from 'react-router-dom';

// CSS for the grid layout
const gridStyles = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr', // Two equal-width columns
  gridGap: '20px', // Adjust the gap between columns as needed
};

function Manageqa() {
  const [qa, setqa] = useState([]);

  useEffect(() => {
    // Fetch the list of qa to populate the list
    fetch('https://dxp2935.uta.cloud/php/retrieve-qas.php')
      .then((response) => response.json())
      .then((data) => {
        setqa(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  const handleDelete = (id) => {
    fetch(`https://dxp2935.uta.cloud/php/delete-qa.php?qa_id=${id}`, {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // If the deletion is successful, fetch the updated list of students
          fetch('https://dxp2935.uta.cloud/php/retrieve-qas.php')
            .then((response) => response.json())
            .then((updatedData) => {
              setqa(updatedData);
            })
            .catch((error) => {
              console.error('Error fetching updated students:', error);
            });
        } else {
          console.error('Error deleting student:', data.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/Blog">Blog</Link>
        <Link to="/ContactUs">Contact Us</Link>
        <Link to="/services">Services</Link>
        <Link to="/">Logout</Link>
      </nav>
      <div className="container">
        <center>
          <h1>Manage qa</h1>
        </center>

        <div style={gridStyles}>
          <Addqa
            refreshqa={() => {
              // Implement a function to refresh the qa list after adding or editing
              fetch('https://dxp2935.uta.cloud/php/add-qa.php')
                .then((response) => response.json())
                .then((data) => {
                  setqa(data);
                })
                .catch((error) => {
                  console.error('Error:', error);
                });
            }}
          />

          <Editqa qas={qa}
            refreshqa={() => {
              // Implement a function to refresh the qa list after adding or editing
              fetch('https://dxp2935.uta.cloud/php/edit-qas.php')
                .then((response) => response.json())
                .then((data) => {
                  setqa(data);
                })
                .catch((error) => {
                  console.error('Error:', error);
                });
            }}
          />
        </div>

        {/* Render the list of qa */}
        <ul>
          {qa ? (
            qa.map((qa) => (
              <li key={qa.id}>
                {qa.name} - {qa.email} - {qa.qa}
                <button onClick={() => handleDelete(qa.id)}>Delete</button>
              </li>
            ))
          ) : (
            <li>No qa available</li>
          )}
        </ul>
      </div>
      <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default Manageqa;
