import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import './styles.css';
import { Link } from 'react-router-dom';
function ReportsPage() {
  // Sample data for the pie chart
  const data = [
    { name: 'Instructors', value: 5 },
    { name: 'Students', value: 10 },
    { name: 'Program Coordinators', value: 4 },
    { name: 'Courses', value: 3 },
    // { name: 'Attendance', value: 200 },
  ];

  // Custom colors for the pie chart
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
      <Link to="/AdminDashboard">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/Blog">Blog</Link>
      <Link to="/ContactUs">Contact Us</Link>
      <Link to="/services">Services</Link>
      <Link to="/">logout</Link>
    </nav>
      <center><h3>Reports</h3></center>
      <div class="container">
      <p>
        This section is intended to show analytics about different users present on this portal
      </p>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ResponsiveContainer width="90%" height={300}>
          <PieChart>
            <Pie data={data} dataKey="value" nameKey="name" innerRadius={60} outerRadius={80}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div> <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer></div>
  );
}

export default ReportsPage;
