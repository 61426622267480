import React , {useState} from 'react';
import { Link } from 'react-router-dom';

const Overview = () => {
    const [isChatOpen, setIsChatOpen] = useState(true);
  
    const toggleChat = () => {
      setIsChatOpen(!isChatOpen);
    };
  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/test">Files</Link>
        <Link to="/Messages">Overview</Link>
        {/* <Link to="/Grades">Grades</Link> */}
        {/* <Link to="/Appointments">Appointments</Link> */}
        <Link to="/FeedbackPage">Feedback</Link>
        <Link to="/">Logout</Link>
      </nav>
      <div className="container">
        <div className="content">
          <h2>Academic Program Overview</h2>
          <p>
            Welcome to the MS in Data Science academic program! This program provides a comprehensive education in data science, covering a wide range of topics, including machine learning, data analysis, and more. You will have the opportunity to learn from experienced faculty and engage in various learning activities.
          </p>
          <p>
            Throughout the program, you will take a variety of courses, complete assignments, and participate in assessments to evaluate your understanding and skills. Please use the links in the navigation bar to access different sections of the program, including course files, grades, and appointments.
          </p>
          <p>
            If you have any questions or need assistance, feel free to use the chatbox on the right. Our chatbot is here to help you with common queries related to the program. You can also reach out to the support team for further assistance.
          </p>
        </div>
        {isChatOpen && (
        <div className="chat-box" style={{ position: 'absolute', bottom: '50px', right: '20px' }}>
          <div className="close-button" onClick={toggleChat}>X</div>
          <div className="chat-title">Chat here !</div>
          <br></br>
            <div className="email-input-wrapper">
                <label htmlFor="userEmail">Your Email:</label>
                <br></br>
                <input
                  type="email"
                  id="userEmail"
                  placeholder="Enter email"
                />
              </div>
          <div className="chat-content">
            {}
          </div>
          <input type="text" className="chat-input" placeholder="Type here" />
          <button className="chat-button">SEND</button>
        </div>
        )}
      </div>
      <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer>
    </div>
  );
};

export default Overview;
