import React from 'react';
import { Link } from 'react-router-dom';

const QAOfficerAdmin = () => {
  const handleEditButtonClick = (qaOfficerName) => {
    // Add functionality for the "Edit" button here
    alert(`Edit QA Officer: ${qaOfficerName}`);
  };

  const handleAddButtonClick = () => {
    // Add functionality for the "Add QA Officer" button here
    alert('Add New QA Officer');
  };

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/student-admin">Students</Link>&nbsp;
        <Link to="/qa-officer-admin">QAOfficer</Link>&nbsp;
        <Link to="/instructor-admin">Instructor</Link>&nbsp;
        <Link to="/appointment-admin">Appointments</Link>
        <Link to="/">Logout</Link>
      </nav>
      <div className="container">
        <div className="content">
          <div className="dashboard">
            <section id="qaofficers">
              <h2>Manage QA Officers</h2>
              {/* Sample QA Officer List */}
              <ul>
                <li>
                  <a href="#edit-qaofficer1" onClick={() => handleEditButtonClick("QA Officer 1")}>
                    QA Officer 1 - Email: qaofficer1@example.com (Edit)
                  </a>
                </li>
                <li>
                  <a href="#edit-qaofficer2" onClick={() => handleEditButtonClick("QA Officer 2")}>
                    QA Officer 2 - Email: qaofficer2@example.com (Edit)
                  </a>
                </li>
              </ul>
              {/* Add/Edit QA Officer Forms */}
              <div id="edit-qaofficer1" className="edit-form">
                <h3>Edit QA Officer 1</h3>
                <form>
                  <label htmlFor="editQaOfficerName1">Name:</label>
                  <input type="text" id="editQaOfficerName1" name="editQaOfficerName1" value="QA Officer 1" required />
                  <label htmlFor="editQaOfficerEmail1">Email:</label>
                  <input type="email" id="editQaOfficerEmail1" name="editQaOfficerEmail1" value="qaofficer1@example.com" required />
                  <button>Edit QA Officer</button>
                </form>
              </div>
              <div id="edit-qaofficer2" className="edit-form">
                <h3>Edit QA Officer 2</h3>
                <form>
                  <label htmlFor="editQaOfficerName2">Name:</label>
                  <input type="text" id="editQaOfficerName2" name="editQaOfficerName2" value="QA Officer 2" required />
                  <label htmlFor="editQaOfficerEmail2">Email:</label>
                  <input type="email" id="editQaOfficerEmail2" name="editQaOfficerEmail2" value="qaofficer2@example.com" required />
                  <button>Edit QA Officer</button>
                </form>
              </div>
              {/* Add QA Officer Form */}
              <h3>Add New QA Officer</h3>
              <form>
                <label htmlFor="qaOfficerName">Name:</label>
                <input type="text" id="qaOfficerName" name="qaOfficerName" required />
                <label htmlFor="qaOfficerEmail">Email:</label>
                <input type="email" id="qaOfficerEmail" name="qaOfficerEmail" required />
                <button onClick={handleAddButtonClick}>Add QA Officer</button>
              </form>
            </section>
          </div>
        </div>
      </div>
      <footer>
        <p>@ copyright EDUHUB-360</p>
      </footer>
    </div>
  );
};

export default QAOfficerAdmin;
