import React from 'react';
import { Link } from 'react-router-dom';
function Services() {
  return (
    <div>
      <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/Blog">Blog</Link>
        <Link to="/contactus">Contact Us</Link>
        <Link to="/services">Services</Link>
        <Link to="/login">Login</Link>
      </nav>
      <div className="text">
        <h2>Our Services Overview</h2>
        <p>At the Msc Academic Program Performance System, we are committed to providing a wide range of services to support our students, instructors, and program stakeholders. Our services are designed to enhance the overall educational experience and streamline program-related activities. Below, you will find an overview of the key services we offer:</p>
      </div>
      <div className="service">
        <h3>1. Course Materials</h3>
        <p>Access course materials, including lecture notes, textbooks, and supplementary resources, to support your learning journey.</p>
      </div>
      <div className="service">
        <h3>2. Exams and Assessments</h3>
        <p>Take online exams and assessments that measure your performance and help you gauge your understanding of course content.</p>
      </div>
      <div className="service">
        <h3>3. Student Progress Tracking</h3>
        <p>Monitor your academic progress, view exam results, and gain insights into your performance throughout the program.</p>
      </div>
      <div className="service">
        <h3>4. Instructor Support</h3>
        <p>Interact with your instructors, seek guidance, and participate in discussions to enhance your learning experience.</p>
      </div>
      <div className="service">
        <h3>5. Program Evaluation</h3>
        <p>Participate in program evaluations and provide valuable feedback to help us continuously improve our academic offerings.</p>
      </div>
    </div>
    <footer>
    <p>&copy; Copyright EDUHUB-360</p>
  </footer>
  </div>
  );
}

export default Services;
