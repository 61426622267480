import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Editqa({ qas, refreshqas }) {
  const [selectedqa, setSelectedqa] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [qa, setqa] = useState('');

  const handleEditqa = () => {
    if (!selectedqa || !name || !email || !qa) {
      alert('Please select a qa and fill out all fields.');
      return;
    }

    // fetch('https://dxp2935.uta.cloud/php/retrieve-qas.php', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //   },
    //   body: `qaId=${selectedqa}&name=${name}&email=${email}&qa=${qa}`,
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     alert(data.message);
    //     if (data.success) {
    //       setSelectedqa('');
    //       setName('');
    //       setEmail('');
    //       setqa('');
    //       refreshqas();
    //     }
    //   })
    //   .catch((error) => {
    //     console.error('Error:', error);
    //   });

      fetch('https://dxp2935.uta.cloud/php/edit-qa.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `qaId=${selectedqa}&name=${name}&email=${email}&qa=${qa}`,
    })
      .then((response) => response.json())
      .then((data) => {
        alert(data.message);
        if (data.success) {
          setSelectedqa('');
          setName('');
          setEmail('');
          setqa('');
          refreshqas();
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      <div className="container">
    <div className="content">
      <div className="dashboard">
        <h2>Edit QA</h2>
        <div className="container">
          {qas && qas.length > 0 ? (
            <div>
              <label htmlFor="qa">Select QA:</label>
              <select id="qa" value={selectedqa} onChange={(e) => setSelectedqa(e.target.value)}>
                <option value="">Select a QA</option>
                {qas.map((qa) => (
                  <option key={qa.id} value={qa.id}>
                    {qa.name}
                  </option>
                ))}
              </select>
              <br />
              {selectedqa && (
                <div>
                  <label htmlFor="editName">New Name:</label>
                  <input type="text" id="editName" value={name} onChange={(e) => setName(e.target.value)} />
                  <br />
                  <label htmlFor="editEmail">New Email:</label>
                  <input type="email" id="editEmail" value={email} onChange={(e) => setEmail(e.target.value)} />
                  <br />
                  <label htmlFor="editqa">New QA:</label>
                  <input type="text" id="editqa" value={qa} onChange={(e) => setqa(e.target.value)} />
                  <br />
                  <button onClick={handleEditqa}>Edit QA</button>
                </div>
              )}
            </div>
          ) : (
            <p>No QA available for editing.</p>
          )}
        </div>
      </div>
    </div>
  </div>
</div>

    

  );
}

export default Editqa;