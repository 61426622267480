import React from 'react';
import './styles.css';
import { Link } from 'react-router-dom';
function ToolsForAssessmentPage() {
  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/Blog">Blog</Link>
      <Link to="/ContactUs">Contact Us</Link>
      <Link to="/services">Services</Link>
      <Link to="/">logout</Link>
    </nav>
      <center><h3>Tools for Assessment</h3></center>
      <div class="container">
      <p>
        Welcome to the "Tools for Assessment" page. We offer a variety of assessment tools and methodologies to help you evaluate and enhance learning outcomes. Below, you'll find some of the key tools and resources available:
      </p>
      <h4>1. Multiple-Choice Tests</h4>
      <p>
        Multiple-choice tests are a commonly used assessment tool. They allow you to quickly assess a broad range of knowledge and are useful for both formative and summative evaluations.
      </p>

      <h4>2. Rubrics</h4>
      <p>
        Rubrics provide clear and detailed criteria for evaluating assignments, projects, and presentations. They are valuable for assessing complex tasks and providing constructive feedback.
      </p>

      <h4>3. Surveys and Questionnaires</h4>
      <p>
        Surveys and questionnaires can help gather feedback from students, parents, and other stakeholders. They are essential for assessing the overall educational experience.
      </p>

      <h4>4. Peer Assessment</h4>
      <p>
        Peer assessment encourages students to evaluate and provide feedback on their peers' work. It promotes collaborative learning and self-assessment skills.
      </p>

      <h4>5. Portfolios</h4>
      <p>
        Portfolios allow students to showcase their work over time. They are excellent for assessing progress, creativity, and the development of critical skills.
      </p>

      <p>
        These are just a few examples of the assessment tools and methodologies we offer. Our goal is to provide you with a comprehensive set of resources to support effective assessment practices in education.
      </p>
    </div> <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer></div>
  );
}

export default ToolsForAssessmentPage;
