import React from 'react';
import './styles.css';
import { Link } from 'react-router-dom';
function Permissions() {
  const permissions = {
    canViewDashboard: true,
    canViewReports: true,
    canViewQualityAssuranceTasks: true,
    canEditQualityAssuranceTasks: true,
    canDeleteQualityAssuranceTasks: true,
    canViewDocuments: true,
    canEditDocuments: true,
    canDeleteDocuments: true,
    canViewUsers: true,
    canEditUsers: false,
    canDeleteUsers: false,
    canEditSettings: false,
  };

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/Blog">Blog</Link>
      <Link to="/ContactUs">Contact Us</Link>
      <Link to="/services">Services</Link>
      <Link to="/">logout</Link>
    </nav>
      <center><h3>QA Officer Permissions</h3></center>
      <div class="container">
      <table>
        <thead>
          <tr>
            <th>Permission</th>
            <th>Access</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(permissions).map((permission, index) => (
            <tr key={index}>
              <td>{permission}</td>
              <td>{permissions[permission] ? 'Allowed' : 'Denied'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div> <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer></div>
  );
}

export default Permissions;
