import React from 'react';

class VerifyEmailPage extends React.Component {
  handleVerifyClick = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const user = urlParams.get('user');
    const token = urlParams.get('token');
    console.log(user,token);

    const apiUrl = `https://dxp2935.uta.cloud/php/verifyemail.php`;

    // Perform API call
    fetch(apiUrl, {
      method: 'POST', // or 'GET' depending on your API
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username: user, token:token }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        alert('Verification successful!');
        if(data.status==0){
          window.location.href='/login';
        }
        else{
          alert(data.message);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Verification failed. Please try again.'); // You can replace this with your desired error handling
      });
  };

  render() {
    return (
      <div style={styles.container}>
        <h2>Email Verification</h2>
        <p>Thank you for registering with Your Website. Please click the button below to verify your email.</p>
        <button onClick={this.handleVerifyClick} style={styles.button}>
          Verify Email
        </button>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  button: {
    padding: '10px',
    fontSize: '16px',
    cursor: 'pointer',
  },
};

export default VerifyEmailPage;
