import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Analytics() {
  const [courseData, setCourseData] = useState([
    {
      courseName: 'CSE 5321-001',
      studentName: 'John Doe',
      performanceData: null,
    }
  ]);

  useEffect(() => {
    // Simulated data for student performance in each course
    const sampleData = [
      {
        quizzesTaken: 5,
        averageQuizScore: 85,
        assignmentsCompleted: 8,
        assignmentAverageScore: 92,
      }
    ];

    setCourseData((prevData) =>
      prevData.map((course, index) => ({
        ...course,
        performanceData: sampleData[index],
      }))
    );
  }, []);

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/Blog">Blog</Link>
        <Link to="/ContactUs">Contact Us</Link>
        <Link to="/services">Services</Link>
        <Link to="/">Logout</Link>
      </nav>
      <h2>Course Analytics</h2>
      {courseData.map((course, index) => (
        <div key={index}>
          <h3>Course: {course.courseName}</h3>
          <h3>Student: {course.studentName}</h3>
          {course.performanceData && (
            <div>
              <h3>Performance Metrics</h3>
              <p>Quizzes Taken: {course.performanceData.quizzesTaken}</p>
              <p>Average Quiz Score: {course.performanceData.averageQuizScore}%</p>
              <p>Assignments Completed: {course.performanceData.assignmentsCompleted}</p>
              <p>Average Assignment Score: {course.performanceData.assignmentAverageScore}%</p>
            </div>
          )}
        </div>
      ))}
      <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default Analytics;
