import React from 'react';
import { Link } from 'react-router-dom';

function Appointments() {
  // Define the upcoming appointments
  const upcomingAppointments = [
    {
      date: 'October 15, 2023',
      time: '2:00 PM - 3:00 PM',
      with: 'Dr. Smith',
    },
    {
      date: 'October 20, 2023',
      time: '10:00 AM - 11:00 AM',
      with: 'Prof. Johnson',
    },
  ];

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/files">Files</Link>
        <Link to="/grades">Grades</Link>
        <Link to="/appointments">Appointments</Link>
        <Link to="/messages">Messages</Link>
        <Link to="/">Logout</Link>
      </nav>
      <div className="container">
        <h2>Appointments</h2>
        <p>Schedule appointments with your instructors and advisors here. You can also view your upcoming appointments.</p>
        <h3>Upcoming Appointments:</h3>
        <ul>
          {upcomingAppointments.map((appointment, index) => (
            <li key={index}>
              <strong>Date:</strong> {appointment.date}
              <br />
              <strong>Time:</strong> {appointment.time}
              <br />
              <strong>With:</strong> {appointment.with}
            </li>
          ))}
        </ul>
      </div>
      <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default Appointments;
