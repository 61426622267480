import React from 'react';
import { Link } from 'react-router-dom';

const ProgcordQA = () => {
  const handleButtonClick = (buttonText) => {
    // Add functionality for the buttons here
    alert(`Clicked: ${buttonText}`);
  };

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/student-qa">Student</Link>
        <Link to="/Messages-qa">Message</Link>
        <Link to="/instructor-qa">Instructor</Link>
        <Link to="/progcord-qa">Coordinator</Link>
        <Link to="/">Logout</Link>
      </nav>
      <center>
        <div className="container">
          <div className="section">
            <h2>Program Review</h2>
            <button onClick={() => handleButtonClick('Program Evaluation')}>Program Evaluation</button>&nbsp;
            <button onClick={() => handleButtonClick('Assessment Reports')}>Assessment Reports</button>&nbsp;
            <button onClick={() => handleButtonClick('Recommendations')}>Recommendations</button>&nbsp;
          </div>
        </div>
      </center>

      <footer>
        <p>@ copyright EDUHUB-360</p>
      </footer>
    </div>
  );
};

export default ProgcordQA;
