import React from 'react';
import { Link } from 'react-router-dom';
import imageurl from './imageurl.jpg';
function Blog() {

        
  const imageUrl = 'https://dxp2935.uta.cloud/wdmapp/uncategorized/blog-for-ms-in-ds-academic-program/'; // Replace with the URL you want to link to
  return (
    <div>
      <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/contactus">Contact Us</Link>
        <Link to="/services">Services</Link>
        <Link to="/login">Login</Link>
      </nav>
          </div>



  <div>
      <br></br><br></br>
      
      <a href={imageUrl} target="_blank" rel="noopener noreferrer">
        <center><img src={imageurl} alt="Blog Post" className="centered-image" /></center>
      </a>
      <center><h2>Blog </h2><br></br>
      <p>Please Click on Image</p></center><br></br>
    </div>
 



    <footer>
    <p>&copy; Copyright EDUHUB-360</p>
  </footer>
  </div>
  );
}
export default Blog;
