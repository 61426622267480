import React from 'react';
import './styles.css';
import { Link } from 'react-router-dom';
function AssignedStudents() {
  // Sample data for assigned students
  const assignedStudents = [
    { id: 1, name: 'John Doe', course: 'CS' },
    { id: 2, name: 'Jane Smith', course: 'DS' },
    { id: 3, name: 'Bob Johnson', course: 'WDM' },
    { id: 4, name: 'Alice Brown', course: 'ML' },
  ];

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/Blog">Blog</Link>
      <Link to="/ContactUs">Contact Us</Link>
      <Link to="/services">Services</Link>
      <Link to="/">Logout</Link>
    </nav>
      <center><h3>Assigned Students</h3></center>
      <div class="container">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Course</th>
          </tr>
        </thead>
        <tbody>
          {assignedStudents.map((student) => (
            <tr key={student.id}>
              <td>{student.id}</td>
              <td>{student.name}</td>
              <td>{student.course}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div> <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer></div>
  );
}

export default AssignedStudents;
