import React from 'react';
import report from './report.jpg';
import './styles.css';
import { Link } from 'react-router-dom';
const Reports = () => {
  return (
    <div>
    <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
      <Link to="/QADashboard">Dashboard</Link>
      <Link to="/about">About</Link>
      <Link to="/Blog">Blog</Link>
      <Link to="/ContactUs">Contact Us</Link>
      <Link to="/services">Services</Link>
      <Link to="/">logout</Link>
    </nav>
    <center><div class="container">
      <h2>Reports</h2>
      <img src={report} alt="Sample" />
      </div></center> <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer></div>
    
  );
};

export default Reports;
