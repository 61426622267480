import React, { useState, useEffect } from 'react';
import './styles.css';
import { Link } from 'react-router-dom';
function TakeAssessment() {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState(Array(5).fill(''));
  const [score, setScore] = useState(null);

  useEffect(() => {
    // Sample questions related to Python, Java, and C
    const sampleQuestions = [
      {
        question: 'What is 2+2?',
        options: ['2', '4', '3', '5'],
        correctAnswer: '4',
      },
      {
        question: 'Which of the following is not a fruit',
        options: ['kiwi', 'orange', 'mango', 'potato'],
        correctAnswer: 'potato',
      },
      {
        question: 'What is the capital of India',
        options: ['New Delhi', 'gujarat', 'mumbai', 'pune'],
        correctAnswer: 'New Delhi',
      },
      {
        question: 'What is 3*2',
        options: ['9', '6', '90', '23'],
        correctAnswer: '6',
      },
    ];

    setQuestions(sampleQuestions);
  }, []);

  const handleAnswerChange = (index, selectedAnswer) => {
    const newAnswers = [...answers];
    newAnswers[index] = selectedAnswer;
    setAnswers(newAnswers);
  };

  const handleSubmit = () => {
    let score = 0;

    for (let i = 0; i < questions.length; i++) {
      if (answers[i] === questions[i].correctAnswer) {
        score++;
      }
    }

    const percentage = (score / questions.length) * 100;
    setScore(percentage);
  };

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/Blog">Blog</Link>
      <Link to="/ContactUs">Contact Us</Link>
      <Link to="/services">Services</Link>
      <Link to="/">logout</Link>
    </nav>
      <h2>Take Assessment</h2>
      <form>
        {questions.map((question, index) => (
          <div key={index}>
            <h3>Question {index + 1}:</h3>
            <p>{question.question}</p>
            <div>
              {question.options.map((option, optionIndex) => (
                <label key={optionIndex}>
                  <input
                    type="radio"
                    name={`question${index}`}
                    value={option}
                    checked={answers[index] === option}
                    onChange={() => handleAnswerChange(index, option)}
                  />
                  {option}
                </label>
              ))}
            </div>
          </div>
        ))}
      </form>
      <button onClick={handleSubmit}>Submit</button>
      {score !== null && (
        <div>
          <h3>Assessment Results</h3>
          <p>Your score: {score.toFixed(2)}%</p>
        </div>
      )}
       <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default TakeAssessment;
