import React, { useState } from 'react';
import { Link } from 'react-router-dom';
function AddCourse({ refreshCourses }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [course, setCourse] = useState('');
  const [program, setProgram] = useState('');

  const handleAddCourse = () => {
    if (!name || !email || !course || !program) {
      alert('Please fill out all fields.');
      return;
    }

    fetch('https://dxp2935.uta.cloud/php/add-course.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `name=${name}&email=${email}&course=${course}&program=${program}`,
    })
      .then((response) => response.json())
      .then((data) => {
        alert(data.message);
        if (data.success) {
          setName('');
          setEmail('');
          setCourse('');
          setProgram('');
          refreshCourses();
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  };

  return (
    <div>
      <div className="container">
        <div className="content">
          <div className="dashboard">
      <h2>Add Course</h2>
      <div className="container">
      <label htmlFor="name">Instructor Name:</label>
      <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
      <br></br>
      <label htmlFor="email">Email:</label>
      <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      <br></br>
      <label htmlFor="course">Course:</label>
      <input type="text" id="course" value={course} onChange={(e) => setCourse(e.target.value)} />
      <br></br>
      <label htmlFor="program">Program:</label>
      <input type="text" id="program" value={program} onChange={(e) => setProgram(e.target.value)} />
      <br></br>
      <button onClick={handleAddCourse}>Add Course</button>
    </div></div></div></div></div>
  );
}

export default AddCourse;
