
// import React, { useState, useEffect } from 'react';
// import AddCoordinator from './AddCoordinator';
// import EditCoordinator from './EditCoordinator';
// import { Link } from 'react-router-dom';

// const gridStyles = {
//   display: 'grid',
//   gridTemplateColumns: '1fr 1fr',
//   gridGap: '20px',
// };

// function ManageCoordinators() {
//   const [coordinators, setCoordinators] = useState([]);

//   useEffect(() => {
//     // Fetch the list of Students to populate the list
//     fetch('https://dxp2935.uta.cloud/php/retrieve-coordinator.php')
//       .then((response) => response.json())
//       .then((data) => {
//         setCoordinators(data);
//       })
//       .catch((error) => {
//         // console.error('Error:', error);
//       });
//   }, []);

//   const handleDelete = (id) => {
//     // Send a DELETE request to delete the student with the specified id
//     fetch(`https://dxp2935.uta.cloud/php/delete-coordinator.php?id=${id}`, {
//       method: 'DELETE',
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.success) {
//           // If the deletion is successful, fetch the updated list of students
//           fetch('https://dxp2935.uta.cloud/php/retrieve-coordinator.php')
//             .then((response) => response.json())
//             .then((updatedData) => {
//               setCoordinators(updatedData);
//             })
//             .catch((error) => {
//               console.error('Error fetching updated coordinators:', error);
//             });
//         } else {
//           console.error('Error deleting coordinators:', data.message);
//         }
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//       });
//   };

//   return (
//     <div>
//       <header>
//         <h1>EDUHUB-360</h1>
//       </header>
//       <nav>
//         <Link to="/">Home</Link>
//         <Link to="/about">About</Link>
//         <Link to="/Blog">Blog</Link>
//         <Link to="/ContactUs">Contact Us</Link>
//         <Link to="/services">Services</Link>
//         <Link to="/">Logout</Link>
//       </nav>
//       <div className="container">
//         <center>
//           <h1>Manage Coordinator</h1>
//         </center>

//         <div style={gridStyles}>
//           <AddCoordinator
//             refreshCoordinators={() => {
//               fetch('https://dxp2935.uta.cloud/php/add-coordinator.php')
//                 .then((response) => response.json())
//                 .then((data) => {
//                   setCoordinators(data);
//                 })
//                 .catch((error) => {
//                   console.error('Error:', error);
//                 });
//             }}
//           />

//           <EditCoordinator coordinators={coordinators} refreshCoordinators={() => {
//             fetch('https://dxp2935.uta.cloud/php/edit-coordinator.php')
//               .then((response) => response.json())
//               .then((data) => {
//                 setCoordinators(data);
//               })
//               .catch((error) => {
//                 console.error('Error:', error);
//               });
//           }} />
//         </div>

//         {/* Render the list of Students with a delete button */}
//         <ul>
//           {coordinators ? (
//             coordinators.map((coordinator) => (
//               <li key={coordinator.id}>
//                 {coordinator.name} - {coordinator.co_email}
//                 <button onClick={() => handleDelete(coordinator.id)}>Delete</button>
//               </li>
//             ))
//           ) : (
//             <li>No coordinators available</li>
//           )}
//         </ul>
//       </div>
//       <footer>
//         <p>&copy; Copyright EDUHUB-360</p>
//       </footer>
//     </div>
//   );
// }

// export default ManageCoordinators;

// import React, { useState, useEffect } from 'react';
// import AddCoordinator from './AddCoordinator';
// import EditCoordinator from './EditCoordinator';
// import { Link } from 'react-router-dom';

// const gridStyles = {
//   display: 'grid',
//   gridTemplateColumns: '1fr 1fr',
//   gridGap: '20px',
// };

// function ManageCoordinators() {
//   const [coordinators, setCoordinators] = useState([]);

//   useEffect(() => {
//     // Fetch the list of Students to populate the list
//     fetch('https://dxp2935.uta.cloud/php/retrieve-coordinator.php')
//       .then((response) => response.json())
//       .then((data) => {
//         setCoordinators(data);
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//       });
//   }, []);

//   const handleDelete = (id) => {
//     // Send a DELETE request to delete the student with the specified id
//     fetch(`https://dxp2935.uta.cloud/php/delete-coordinator.php?id=${id}`, {
//       method: 'DELETE',
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.success) {
//           // If the deletion is successful, fetch the updated list of students
//           fetch('https://dxp2935.uta.cloud/php/retrieve-coordinator.php')
//             .then((response) => response.json())
//             .then((updatedData) => {
//               setCoordinators(updatedData);
//             })
//             .catch((error) => {
//               console.error('Error fetching updated coordinators:', error);
//             });
//         } else {
//           console.error('Error deleting coordinators:', data.message);
//         }
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//       });
//   };

//   return (
//     <div>
//       <header>
//         <h1>EDUHUB360</h1>
//       </header>
//       <nav>
//         <Link to="/">Home</Link>
//         <Link to="/about">About</Link>
//         <Link to="/Blog">Blog</Link>
//         <Link to="/ContactUs">Contact Us</Link>
//         <Link to="/services">Services</Link>
//         <Link to="/">Logout</Link>
//       </nav>
//       <div className="container">
//         <center>
//           <h1>Manage Coordinator</h1>
//         </center>

//         <div style={gridStyles}>
//           <AddCoordinator
//             refreshCoordinators={() => {
//               fetch('https://dxp2935.uta.cloud/php/retrieve-coordinator.php')
//                 .then((response) => response.json())
//                 .then((data) => {
//                   setCoordinators(data);
//                 })
//                 .catch((error) => {
//                   console.error('Error:', error);
//                 });
//             }}
//           />

//           <EditCoordinator coordinators={coordinators} refreshCoordinators={() => {
//             fetch('https://dxp2935.uta.cloud/php/retrieve-coordinator.php')
//               .then((response) => response.json())
//               .then((data) => {
//                 setCoordinators(data);
//               })
//               .catch((error) => {
//                 console.error('Error:', error);
//               });
//           }} />
//         </div>

//         {/* Render the list of Students with a delete button */}
//         <ul>
//           {coordinators ? (
//             coordinators.map((coordinator) => (
//               <li key={coordinator.id}>
//                 {coordinator.name} - {coordinator.co_email}
//                 <button onClick={() => handleDelete(coordinator.id)}>Delete</button>
//               </li>
//             ))
//           ) : (
//             <li>No coordinators available</li>
//           )}
//         </ul>
//       </div>
//       <footer>
//         <p>&copy; Copyright EDUHUB360</p>
//       </footer>
//     </div>
//   );
// }

// export default ManageCoordinators;

// import React, { useState, useEffect } from 'react';
// import AddCoordinator from './AddCoordinator';
// import EditCoordinator from './EditCoordinator';
// import { Link } from 'react-router-dom';

// const gridStyles = {
//   display: 'grid',
//   gridTemplateColumns: '1fr 1fr',
//   gridGap: '20px',
// };

// function ManageCoordinators() {
//   const [coordinators, setCoordinators] = useState([]);

//   useEffect(() => {
//     // Fetch the list of Students to populate the list
//     fetch('https://dxp2935.uta.cloud/php/retrieve-coordinator.php')
//       .then((response) => response.json())
//       .then((data) => {
//         setCoordinators(data);
//       })
//       .catch((error) => {
//         // console.error('Error:', error);
//       });
//   }, []);

//   const handleDelete = (id) => {
//     // Send a DELETE request to delete the student with the specified id
//     fetch(`https://dxp2935.uta.cloud/php/delete-coordinator.php?id=${id}`, {
//       method: 'DELETE',
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.success) {
//           // If the deletion is successful, fetch the updated list of students
//           fetch('https://dxp2935.uta.cloud/php/retrieve-coordinator.php')
//             .then((response) => response.json())
//             .then((updatedData) => {
//               setCoordinators(updatedData);
//             })
//             .catch((error) => {
//               console.error('Error fetching updated coordinators:', error);
//             });
//         } else {
//           console.error('Error deleting coordinators:', data.message);
//         }
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//       });
//   };

//   return (
//     <div>
//       <header>
//         <h1>EDUHUB-360</h1>
//       </header>
//       <nav>
//         <Link to="/">Home</Link>
//         <Link to="/about">About</Link>
//         <Link to="/Blog">Blog</Link>
//         <Link to="/ContactUs">Contact Us</Link>
//         <Link to="/services">Services</Link>
//         <Link to="/">Logout</Link>
//       </nav>
//       <div className="container">
//         <center>
//           <h1>Manage Coordinator</h1>
//         </center>

//         <div style={gridStyles}>
//           <AddCoordinator
//             refreshCoordinators={() => {
//               fetch('https://dxp2935.uta.cloud/php/add-coordinator.php')
//                 .then((response) => response.json())
//                 .then((data) => {
//                   setCoordinators(data);
//                 })
//                 .catch((error) => {
//                   console.error('Error:', error);
//                 });
//             }}
//           />

//           <EditCoordinator coordinators={coordinators} refreshCoordinators={() => {
//             fetch('https://dxp2935.uta.cloud/php/edit-coordinator.php')
//               .then((response) => response.json())
//               .then((data) => {
//                 setCoordinators(data);
//               })
//               .catch((error) => {
//                 console.error('Error:', error);
//               });
//           }} />
//         </div>

//         {/* Render the list of Students with a delete button */}
//         <ul>
//           {coordinators ? (
//             coordinators.map((coordinator) => (
//               <li key={coordinator.id}>
//                 {coordinator.name} - {coordinator.co_email}
//                 <button onClick={() => handleDelete(coordinator.id)}>Delete</button>
//               </li>
//             ))
//           ) : (
//             <li>No coordinators available</li>
//           )}
//         </ul>
//       </div>
//       <footer>
//         <p>&copy; Copyright EDUHUB-360</p>
//       </footer>
//     </div>
//   );
// }

// export default ManageCoordinators;

import React, { useState, useEffect } from 'react';
import AddCoordinator from './AddCoordinator';
import EditCoordinator from './EditCoordinator';
import { Link } from 'react-router-dom';

const gridStyles = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: '20px',
};

function ManageCoordinators() {
  const [coordinators, setCoordinators] = useState([]);

  useEffect(() => {
    // Fetch the list of Students to populate the list
    fetch('https://dxp2935.uta.cloud/php/retrieve-coordinator.php')
      .then((response) => response.json())
      .then((data) => {
        setCoordinators(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  const handleDelete = (id) => {
    // Send a DELETE request to delete the student with the specified id
    fetch(`https://dxp2935.uta.cloud/php/delete-coordinator.php?id=${id}`, {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // If the deletion is successful, fetch the updated list of students
          fetch('https://dxp2935.uta.cloud/php/retrieve-coordinator.php')
            .then((response) => response.json())
            .then((updatedData) => {
              setCoordinators(updatedData);
            })
            .catch((error) => {
              console.error('Error fetching updated coordinators:', error);
            });
        } else {
          console.error('Error deleting coordinators:', data.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      <header>
        <h1>EDUHUB360</h1>
      </header>
      <nav>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/Blog">Blog</Link>
        <Link to="/ContactUs">Contact Us</Link>
        <Link to="/services">Services</Link>
        <Link to="/">Logout</Link>
      </nav>
      <div className="container">
        <center>
          <h1>Manage Coordinator</h1>
        </center>

        <div style={gridStyles}>
          <AddCoordinator
            refreshCoordinators={() => {
              fetch('https://dxp2935.uta.cloud/php/retrieve-coordinator.php')
                .then((response) => response.json())
                .then((data) => {
                  setCoordinators(data);
                })
                .catch((error) => {
                  console.error('Error:', error);
                });
            }}
          />

          <EditCoordinator coordinators={coordinators} refreshCoordinators={() => {
            fetch('https://dxp2935.uta.cloud/php/retrieve-coordinator.php')
              .then((response) => response.json())
              .then((data) => {
                setCoordinators(data);
              })
              .catch((error) => {
                console.error('Error:', error);
              });
          }} />
        </div>

        {/* Render the list of Students with a delete button */}
        <ul>
          {coordinators ? (
            coordinators.map((coordinator) => (
              <li key={coordinator.id}>
                {coordinator.name} - {coordinator.co_email}
                <button onClick={() => handleDelete(coordinator.id)}>Delete</button>
              </li>
            ))
          ) : (
            <li>No coordinators available</li>
          )}
        </ul>
      </div>
      <footer>
        <p>&copy; Copyright EDUHUB360</p>
      </footer>
    </div>
  );
}

export default ManageCoordinators;