import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const QADashboard = () => {
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [userEmail, setUserEmail] = useState('');

  

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/managestudents">Student</Link>
        <Link to="/chat">Chat</Link>
        <Link to="/manageinstructors">Instructor</Link>
        <Link to="/progcord-qa">Coordinator</Link>
        <Link to="/">Logout</Link>
      </nav>
      <div className="container">
        <div className="content">
          <div className="dashboard">
            <div className="section">
              <h2>QA Officer</h2>
              <div className="link-wrapper" style={{ marginBottom: '10px' }}>
                <button><Link to="/reports">Reports</Link></button>
              </div>
              <div className="link-wrapper" style={{ marginBottom: '10px' }}>
                <button><Link to="/feedbackpage">Give Feedback</Link></button>
              </div>
              <div className="link-wrapper" style={{ marginBottom: '10px' }}>
                <button><Link to="/qualitystandardspage">Quality Standards</Link></button>
              </div>
            </div>
          </div>
        </div>

      </div>
      <footer>
        <p>@ copyright EDUHUB-360</p>
      </footer>
    </div>
  );
};

export default QADashboard;
