import React, { useState } from 'react';
import './styles.css';
import { Link } from 'react-router-dom';
function GradePeers() {
  const [grades, setGrades] = useState({});
  const [comments, setComments] = useState({});

  // Example peer data
  const peers = [
    { id: 1, name: 'Peer 1' },
    { id: 2, name: 'Peer 2' },
    { id: 3, name: 'Peer 3' },
  ];

  const handleGradeChange = (peerId, event) => {
    const newGrades = { ...grades };
    newGrades[peerId] = parseInt(event.target.value);
    setGrades(newGrades);
  };

  const handleCommentChange = (peerId, event) => {
    const newComments = { ...comments };
    newComments[peerId] = event.target.value;
    setComments(newComments);
  };

  const handleSubmitGrades = () => {
    // Here, you can send the grades and comments to your backend for storage and further processing.
    console.log('Grades:', grades);
    console.log('Comments:', comments);
  };

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/Blog">Blog</Link>
      <Link to="/ContactUs">Contact Us</Link>
      <Link to="/services">Services</Link>
      <Link to="/">logout</Link>
    </nav>
      <center><h1>Peer Grading</h1></center>
      <div className="container">
        <div className="content">
          <div className="dashboard">
      <p>Grade your peers' work and provide comments:</p>
      {peers.map((peer) => (
        <div key={peer.id}>
          <h3>{peer.name}</h3>
          <div>
            <label>Grade: </label>
            <select onChange={(event) => handleGradeChange(peer.id, event)}>
              <option value="5">5 (Excellent)</option>
              <option value="4">4 (Good)</option>
              <option value="3">3 (Average)</option>
              <option value="2">2 (Below Average)</option>
              <option value="1">1 (Poor)</option>
            </select>
          </div>
          <div>
            <label>Comments: </label>
            <textarea
              rows="4"
              cols="50"
              onChange={(event) => handleCommentChange(peer.id, event)}
            ></textarea>
          </div>
        </div>
      ))}
      <button onClick={handleSubmitGrades}>Submit Grades</button>
    </div></div></div> <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer></div>
  );
}

export default GradePeers;
