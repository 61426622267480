import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch ,Link } from 'react-router-dom';
import './styles.css';

// Import all the components
import About from './components/About';
import AdminDashboard from './components/AdminDashboard';
import AppointmentAdmin from './components/AppointmentAdmin';
import AppointmentInstructor from './components/AppointmentInstructor';
import Appointments from './components/Appointments';
import ContactUs from './components/ContactUs';
import Files from './components/Files';
import FilesInstructor from './components/FilesInstructor';
import ForgotPassword from './components/ForgotPassword';
import Grades from './components/Grades';
import Home from './components/index';
import InstructorAdmin from './components/InstructorAdmin';
import InstructorDashboard from './components/InstructorDashboard';
import InstructorPro from './components/InstructorPro';
import InstructorQA from './components/InstructorQA';
import Login from './components/Login';
import Messages from './components/Messages';
import MessagesInstructor from './components/MessagesInstructor';
import MessagesPro from './components/MessagesPro';
import MessagesQA from './components/MessagesQA';
import ProgcordQA from './components/ProgcordQA';
import ProgramCoordinatorDashboard from './components/ProgramCoordinatorDashboard';
import QADashboard from './components/QADashboard';
import QAOfficerAdmin from './components/QAOfficerAdmin';
import Services from './components/Services';
import SignUp from './components/SignUp';
import StudentAdmin from './components/StudentAdmin';
import StudentDashboard from './components/StudentDashboard';
import StudentInstructor from './components/StudentInstructor';
import StudentPro from './components/StudentPro';
import StudentQA from './components/StudentQA';
import Blog from './components/Blog';
import GradePeers from './components/gradepeers';
import Course from './components/course';
import ExamCreation from './components/ExamCreation';
import Exam from './components/Exam';
import TakeAssessment from './components/TakeAssessment';
import Report_S from './components/Report_S';
import Analytics from './components/Analytics';
import AssignedStudents from './components/AssignedStudents';
import Permissions from './components/Permissions';
import ReportQA from './components/ReportQA';
import ReportStudent from './components/ReportStudent';
import Performance from './components/Performance';
import ReportsPage from './components/ReportsPage';
import ProgramReviewPage from './components/ProgramReviewPage';
import StudentSupportPage from './components/StudentSupportPage';
import FeedbackPage from './components/FeedbackPage'; // Create a component for Feedback page
import ToolsForAssessmentPage from './components/ToolsForAssessmentPage'; // Create a component for Tools for Assessment page
import ImprovementPage from './components/ImprovementPage'; // Create a component for Improvement page
import FacultyDevelopmentPage from './components/FacultyDevelopmentPage'; // Create a component for Faculty Development page
import QualityStandardsPage from './components/QualityStandardsPage'; 
import Reports from './components/Reports'; // Import other components
import ManageInstructors from './components/ManageInstructors';
import ManageStudents from './components/ManageStudents';
import ManageCoordinator from './components/ManageCoordinator';
import ManageCourses from './components/ManageCourse';
import ManageQA from './components/ManageQA';
import Feedback from './components/Feedback';
import AddInstructor from './components/AddInstructor.jsx';
import EditInstructor from './components/EditInstructor.jsx';
import AddStudent from './components/AddStudent.jsx';
import EditStudent from './components/EditStudent.jsx';
import AddCoordinator from './components/AddCoordinator.jsx';
import EditCoordinator from './components/EditCoordinator.jsx';
import AddQA from './components/Addqa.jsx';
import EditQA from './components/Editqa.jsx';
import AddCourse from './components/AddCourse.jsx';
import EditCourse from './components/EditCourse.jsx';
import test from './components/test.jsx';
import Overview from './components/Overview';
import AddStudentCourse from './components/add_student_course';
import Addprogram from './components/Addprogram.jsx';
import Manageprogram from './components/Manageprogram.jsx';
import Editprogram from './components/Editprogram.jsx';
import FeedbackDisplay from './components/FeedbackDisplay';
import Gradeexam from './components/Gradeexam.jsx';
import Addquestions from './components/Addquestions'
import TakeExams from './components/TakeExams.jsx'
import Testsignup from './components/Testsignup.jsx';
import verifyemailpg from './components/verifyemailpg.jsx';
import chat from './components/chat.jsx';

function App() {
  return (
    <Router>
      <div>
  <Switch>
  <Route exact path="/about" component={About} />
  <Route exact path="/Blog" component={Blog} />
  <Route exact path="/admindashboard" component={AdminDashboard} />
  <Route exact path="/appointment-admin" component={AppointmentAdmin} />
  <Route exact path="/appointment-instructor" component={AppointmentInstructor} />
  <Route exact path="/appointments" component={Appointments} />
  <Route exact path="/contactus" component={ContactUs} />
  <Route exact path="/files" component={Files} />
  <Route exact path="/files-instructor" component={FilesInstructor} />
  <Route exact path="/forgotpassword" component={ForgotPassword} />
  <Route exact path="/grades" component={Grades} />
 
  <Route exact path="/instructor-admin" component={InstructorAdmin} />
  <Route exact path="/InstructorDashboard" component={InstructorDashboard} />
  <Route exact path="/instructor-pro" component={InstructorPro} />
  <Route exact path="/instructor-qa" component={InstructorQA} />
  <Route exact path="/login" component={Login} />
  <Route exact path="/messages" component={Messages} />
  <Route exact path="/messages-instructor" component={MessagesInstructor} />
  <Route exact path="/messages-pro" component={MessagesPro} />
  <Route exact path="/messages-qa" component={MessagesQA} />
  <Route exact path="/progcord-qa" component={ProgcordQA} />
  <Route exact path="/programcoordinatordashboard" component={ProgramCoordinatorDashboard} />
  <Route exact path="/QADashboard" component={QADashboard} />
  <Route exact path="/qa-officer-admin" component={QAOfficerAdmin} />
  <Route exact path="/services" component={Services} />
  <Route exact path="/signup" component={SignUp} />
  <Route exact path="/student-admin" component={StudentAdmin} />
  <Route exact path="/StudentDashboard" component={StudentDashboard} />
  <Route exact path="/student-instructor" component={StudentInstructor} />
  <Route exact path="/student-pro" component={StudentPro} />
  <Route exact path="/student-qa" component={StudentQA} />
  <Route exact path="/blog" component={Blog} /> {/* Add a route for the "Blog" component */}
  <Route exact path="/gradepeers" component={GradePeers} /> {/* Add a route for the "GradePeers" component */}
  <Route exact path="/course" component={Course} /> {/* Add a route for the "Course" component */}
  <Route exact path="/exam-creation" component={ExamCreation} /> {/* Add a route for the "ExamCreation" component */}
  <Route exact path="/take-assessment" component={TakeAssessment} /> {/* Add a route for the "TakeAssessment" component */}
  <Route exact path="/report-s" component={Report_S} /> {/* Add a route for the "Report_S" component */}
  <Route exact path="/analytics" component={Analytics} /> {/* Add a route for the "Analytics" component */}
  <Route exact path="/assigned-students" component={AssignedStudents} /> {/* Add a route for the "AssignedStudents" component */}
  <Route exact path="/permissions" component={Permissions} /> {/* Add a route for the "Permissions" component */}
  <Route exact path="/reportqa" component={ReportQA} /> {/* Add a route for the "ReportQA" component */}
  <Route exact path="/reportstudent" component={ReportStudent} /> {/* Add a route for the "ReportStudent" component */}
  <Route exact path="/performance" component={Performance} /> {/* Add a route for the "Performance" component */}
  <Route exact path="/reportspage" component={ReportsPage} /> {/* Add a route for the "ReportsPage" component */}
  <Route exact path="/programreviewpage" component={ProgramReviewPage} /> {/* Add a route for the "ProgramReviewPage" component */}
  <Route exact path="/studentsupportpage" component={StudentSupportPage} /> {/* Add a route for the "StudentSupportPage" component */}
  <Route exact path="/feedbackpage" component={FeedbackPage} /> {/* Add a route for the "FeedbackPage" component */}
  <Route exact path="/toolsforassessmentpage" component={ToolsForAssessmentPage} /> {/* Add a route for the "ToolsForAssessmentPage" component */}
  <Route exact path="/improvementpage" component={ImprovementPage} /> {/* Add a route for the "ImprovementPage" component */}
  <Route exact path="/facultydevelopmentpage" component={FacultyDevelopmentPage} /> {/* Add a route for the "FacultyDevelopmentPage" component */}
  <Route exact path="/qualitystandardspage" component={QualityStandardsPage} /> {/* Add a route for the "QualityStandardsPage" component */}
  <Route exact path="/reports" component={Reports} /> {/* Add a route for the "Reports" component */}
  <Route exact path="/manageinstructors" component={ManageInstructors} /> {/* Add a route for the "ManageInstructors" component */}
  <Route exact path="/managestudents" component={ManageStudents} /> {/* Add a route for the "ManageStudents" component */}
  <Route exact path="/managecoordinator" component={ManageCoordinator} /> {/* Add a route for the "ManageCoordinator" component */}
  <Route exact path="/managecourses" component={ManageCourses} /> {/* Add a route for the "ManageCourses" component */}
  <Route exact path="/manageqa" component={ManageQA} /> {/* Add a route for the "ManageQA" component */}
  <Route exact path="/feedback" component={Feedback} /> {/* Add a route for the "Feedback" component */}
  <Route exact path="/addinstructor" component={AddInstructor} /> {/* Add a route for the "AddInstructor" component */}
  <Route exact path="/editinstructor" component={EditInstructor} /> {/* Add a route for the "EditInstructor" component */}
  <Route exact path="/addstudent" component={AddStudent} /> {/* Add a route for the "AddStudent" component */}
  <Route exact path="/editstudent" component={EditStudent} /> {/* Add a route for the "EditStudent" component */}
  <Route exact path="/addcoordinator" component={AddCoordinator} /> {/* Add a route for the "AddCoordinator" component */}
  <Route exact path="/editcoordinator" component={EditCoordinator} /> {/* Add a route for the "EditCoordinator" component */}
  <Route exact path="/addqa" component={AddQA} /> {/* Add a route for the "AddQA" component */}
  <Route exact path="/editqa" component={EditQA} /> {/* Add a route for the "EditQA" component */}
  <Route exact path="/addcourse" component={AddCourse} /> {/* Add a route for the "AddCourse" component */}
  <Route exact path="/editcourse" component={EditCourse} /> {/* Add a route for the "EditCourse" component */}
  <Route exact path="/test" component={test} />
  <Route exact path='/Overview' component={Overview}/>
  <Route exact path='/add_student_course' component={AddStudentCourse}/>
  <Route exact path='/Addprogram' component={Addprogram}/>
  <Route excat path='/Manageprogram' component={Manageprogram}/>
  <Route exact path='/Editprogram' component={Editprogram}/>
  <Route exact path='/FeedbackDisplay' component={FeedbackDisplay}/>
  <Route excat path='/Gradeexam' component={Gradeexam}/>
  <Route excat path='/Addquestions/:exam_id' component={Addquestions}/>
  <Route excat path='/TakeExams/:id' component={TakeExams}/>
  <Route excat path='/Testsignup' component={Testsignup}/>
  <Route excat path='/verifyemailpg' component={verifyemailpg}/>
  <Route excat path='/chat' component={chat}/>

  <Route exact path="/exam" component={Exam} /> {/* Add a route for the "Exam" component */}
  <Route exact path="/" component={Home} />


</Switch>

      </div>
    </Router>
  );
}

export default App;
