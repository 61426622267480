import React from 'react';
import { Link } from 'react-router-dom';

const StudentQA = () => {
  // Define functions for button actions here

  const handleStudentResources = () => {
    // Implement action for the Student Resources button
    alert('Student Resources clicked');
  };

  const handleAcademicCounseling = () => {
    // Implement action for the Academic Counseling button
    alert('Academic Counseling clicked');
  };

  const handleTutoringServices = () => {
    // Implement action for the Tutoring Services button
    alert('Tutoring Services clicked');
  };

  const handleFAQs = () => {
    // Implement action for the FAQs button
    alert('FAQs clicked');
  };

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/student-qa">Student</Link>
        <Link to="/Messages-qa">Message</Link>
        <Link to="/instructor-qa">Instructor</Link>
        <Link to="/procord-qa">Coordinator</Link>
        <Link to="/">Logout</Link>
      </nav>      <center>
        <div className="container">
          <div className="section">
            <h2>Student Support</h2>
            <button onClick={handleStudentResources}>Student Resources</button>&nbsp;
            <button onClick={handleAcademicCounseling}>Academic Counseling</button>&nbsp;
            <button onClick={handleTutoringServices}>Tutoring Services</button>&nbsp;
            <button onClick={handleFAQs}>FAQs</button>
          </div>
        </div>
      </center>

      <footer>
        <p>@ copyright EDUHUB-360</p>
      </footer>
    </div>
  );
};

export default StudentQA;
