import React, { useState } from 'react';
import { Link } from 'react-router-dom';
function AddCoordinator({ refreshCoordinators }) {
  const [name, setName] = useState('');
  const [description, setdescription] = useState('');
  const [co_email, setCo_email] = useState('');

  const handleAddCoordinator = () => {
    if (!name || !description || !co_email) {
      alert('Please fill out all fields.');
      return;
    }

    fetch('https://dxp2935.uta.cloud/php/add-coordinator.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `name=${name}&description=${description}&co_email=${co_email}`,
    })
      .then((response) => response.json())
      .then((data) => {
        alert(data.message);
        if (data.success) {
          setName('');
          setdescription('');
          setCo_email('');  
          refreshCoordinators();
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
    <div className="container">
        <div className="content">
          <div className="dashboard">
            <h2>Add coordinator</h2>
      <div className="container">
      <label htmlFor="name">Name:</label>
      <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
      <br />
        <label htmlFor="description">Description:</label>
        <input type="text" id="description" value={description} onChange={(e) => setdescription(e.target.value)} />
        <br />
        <label htmlFor="co_email">Email:</label>
        <input type="email" id="co_email" value={co_email} onChange={(e) => setCo_email(e.target.value)} />
        <br />
      
      <button onClick={handleAddCoordinator}>Add Coordinator</button>
    </div></div></div></div> </div>
  );
}

export default AddCoordinator;
